<template>
<el-form ref="form" :model="role" label-width="80px">
    <el-form-item :label="msg('角色名')">
        <el-input v-model="role.name"></el-input>
    </el-form-item>
    <el-form-item :label="msg('显示名')">
        <el-input v-model="role.label"></el-input>
    </el-form-item>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'AccountEdit',
  props:{
      role:Object
  },
  methods:{
    onSubmit(){
        if(this.role.disabled){
            this.role.disabled=1;
        }else{
            this.role.disabled=0;
        }

        var url = null;
        if(this.role.id==null){
            url = this.$kit.api.roleCreate;
        }else{
            url = this.$kit.api.roleUpdate;
        }

        var _this = this;
        this.$axios.post(url, this.role)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
