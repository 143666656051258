<template>
<el-form ref="form" :model="menu" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('菜单名')">
                <el-input v-model="menu.label"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('URL')">
                <el-input v-model="menu.url"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('图标')">
                <el-input  v-model="menu.icon" v-popover:iconPopover :placeholder="msg('请选择图标')" suffix-icon="el-icon-arrow-down">
                    <template slot="prepend"><i :class="['iconfont', menu.icon?menu.icon:'']"></i></template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
             <el-form-item :label="msg('上级菜单')" >
                <el-input v-model="parent.label" v-popover:menusPopover></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-form-item :label="msg('排序')">
        <el-input v-model="menu.seq" :placeholder="msg('数字越大越靠前')" ></el-input>
    </el-form-item>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>

    <el-popover
        ref="iconPopover"
        placement="bottom"
        width="300"
        trigger="focus"
        v-model="iconPopoverVisiable"
        >
        <ul class="icon-list">
            <li v-for="icon in icons" :key="icon" 
                    @click="iconPopoverVisiable=false; menu.icon=icon">
                <i :class="['iconfont', icon]"></i>
            </li>
        </ul>
    </el-popover>

    <el-popover
        ref="menusPopover"
        placement="bottom"
        width="300"
        trigger="focus"
        v-model="menusPopoverVisiable"
        >
        <el-tree :data="menus" default-expand-all node-key="id" 
              ref="menuTreeSelector" 
              :props="defaultProps" @node-click="selectedMenuNode">
        </el-tree>
    </el-popover>
</el-form> 
</template>

<script>
export default {
  name: 'MenuEdit',
  data(){
    return{
        icons:this.$kit.icons,
        iconPopoverVisiable: false,
        menusPopoverVisiable:false,
        defaultProps:["id", "label"], 
    }
  },
  props:{
      menus:Array,
      parent: Object,
      menu: Object,
  },
  mounted: function(){
       
  },
  methods:{
    onSubmit(){

        var url = null;
        if(this.menu.id==null){
            url = this.$kit.api.menuCreate;
        }else{
            url = this.$kit.api.menuUpdate;
        }

        var _this = this;
        this.$axios.post(url, this.menu)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    },
    selectedMenuNode(menu, node, self){
        this.parent.label = menu.label;
        this.menu.parentId = menu.id;
        this.menusPopoverVisiable = false;

    }
  }
}
</script>

<style class="scss" scoped>
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
