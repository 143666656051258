<template>
<div>
  <el-table :data="permissions" style="width: 100%" :height="400" size="mini" ref="permissionTable" stripe>
    <el-table-column type="selection"  min-width="55" ></el-table-column>
    <el-table-column prop="id" :label="msg('ID')" min-width="70" ></el-table-column>
    <el-table-column prop="label" :label="msg('权限名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
  </el-table>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantPermission',
  mounted:function(){
    this.getPermission();
  },
  props:{
    roleId: Number
  },
  data(){
    return{
      permissions: [],
      rolePermissions:[]
    }
  }, 
  methods: {
    buildSelected(roleId){ 
      var _this = this;

      var table =  this.$refs.permissionTable;
      table.clearSelection();

      this.axios.get(this.$kit.api.rolePermission+"?id="+roleId).then(function (result) {
        if(result.status){
          if(_this.rolePermissions.length>0)
            _this.rolePermissions.splice(0, _this.rolePermissions.length);

            result.data.forEach(rp => _this.rolePermissions.push(rp));
            
            _this.permissions.forEach(p => {

              var rp = _this.rolePermissions.find( r => r.id == p.id);
              if(rp){
                table.toggleRowSelection(p);
              }
            })

        }else{
          _this.$message(result.msg); 
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }, 
    getPermission(){
      var _this=this;
      this.axios.post(this.$kit.api.permList, {start: 0, length: 9999}).then(function (result) {
        if(result.status){
          if(_this.permissions.length>0)
            _this.permissions.splice(0, _this.permissions.length);
            var pers = result.data.data;
            pers.forEach(p => _this.permissions.push(p));

        }else{
          _this.$message(result.msg); 
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    onSubmit(){
        var _this=this;
        //{roleId: 1, permissionId: "3"}
        var selectes = this.$refs.permissionTable.selection;
        var rps = [];
        selectes.forEach(s => rps.push({roleId: this.roleId, permissionId: s.id}));

        this.$axios.post(this.$kit.api.roleGrantPermission, rps)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || _this.msg('更新成功'), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg");
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });
    },

  },

  

}
</script>

<style class="scss" scoped>


</style>
