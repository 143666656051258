<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('菜单管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh(true)" circle></el-button>
    </el-col>
  </el-row>
  
  <el-row :gutter="10">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" >
      <div class="row-wrap" :style="{height: isMinScreen? 'auto':docHeight+'px'}" style="margin-left:-5px;">
        <el-tree :data="menus" default-expand-all node-key="id" 
              ref="menuTree" :props="defaultProps" @node-click="selectNode">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span v-if="data.id && data.id!=0">
              <el-link type="primary" @click.stop="add(data)" style="margin-right:8px; font-size:16px;"><i class="el-icon-circle-plus-outline"></i></el-link>
              <el-link type="success" @click.stop="editRow(data, true)" style="margin-right:8px; font-size:16px;"><i class="el-icon-edit"></i></el-link>
              <el-link type="danger" @click.stop="deleteRow(data)" style="font-size:16px;"><i class="el-icon-delete"></i></el-link>
            </span>
          </span>
        </el-tree>
      </div>
    </el-col>
    <el-col :xs="24" :sm="16" :lg="18" :xl="20" >
      <div>
        <div class="row-wrap" style="display:flex; justify-content: space-between; margin-right:-5px;" >
          <div class="el-page-header__content">
            <i :class="['iconfont', parentMenu && parentMenu.icon ? parentMenu.icon : '']"></i>{{parentMenu ? parentMenu.label: ""}}
          </div>
          <el-button type="primary" size="mini" @click="add(parentMenu)">
            <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
            <span>{{msg('添加')}}</span>
          </el-button>
        </div>
        <div class="row-wrap" :style="{height: isMinScreen? 'auto':(docHeight-52)+'px'}" style="margin-right:-5px;">
          <el-table :data="childrens" style="width: 100%" size="mini" ref="dataTable"  stripe>
            <el-table-column prop="id" :label="msg('ID')" min-width="70"></el-table-column>
            <el-table-column prop="label" :label="msg('菜单名')" min-width="90"></el-table-column>
            <el-table-column prop="icon" :label="msg('图标')" min-width="60">
              <template slot-scope="scope">
                <i :class="['iconfont', scope.row.icon?scope.row.icon:'']"></i>
              </template>
            </el-table-column>
            <el-table-column prop="parentId" :label="msg('父级菜单')" min-width="100" ></el-table-column>
            <el-table-column prop="seq" :label="msg('排序')" min-width="60"></el-table-column>
            <el-table-column prop="" :label="msg('操作')" min-width="100" :fixed="isMinScreen?'right':false">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editRow(scope.row)"></el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteRow(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen">
    <menu-edit :menus="menus" :menu="currMenu" :parent="parentMenu"
      v-on:closeDg="closeEditDialog" ref="menuEdit"/>
  </el-dialog>
</div>
</template> 

<script>
// @ is an alias to /src
import MenuEdit from "./MenuEdit.vue";

export default {
  name: 'Menu',
  components: {
    MenuEdit:MenuEdit
  },
  mounted:function(){
    this.getMenus();
  },
  data(){
    return{
        menus: [],
        origins: [],
        childrens:[],
        defaultProps:["id", "label"],
        currMenu: null,
        parentMenu: null,
        dgTitle: null,
        dgVisible: false,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 160;
        }
    }
  },
  methods: {
    refresh: function(refresh){  //刷新列表
      this.getMenus(refresh);
    },
    getMenus(refresh){
      var _this = this;
      this.$axios.post(this.$kit.api.menuList, {start: 0, length: 9999})
            .then(function(result){
                if(result.status){
                  var data = result.data.data;

                  if(_this.menus.length>0)
                    _this.menus.splice(0, _this.menus.length);
                  if(_this.origins.length>0)
                    _this.origins.splice(0, _this.origins.length);

                  if(_this.childrens.length>0)
                    _this.childrens.splice(0, _this.childrens.length);

                  _this.buildTree(data, refresh);

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    buildTree(menus, refresh){
      var map = {};
      menus.forEach(m => map[m.id] = m);
      
      for(let key in map){
        var m = map[key];
        if(m.parentId){ 
          var parent = map[m.parentId];
          if(parent){
            parent.children.push(m);
          }
        }
      }

      if(!refresh && this.currMenu !=null ){
        this.currMenu = map[this.currMenu.id];
      }
      
      var ms = [];

      for(let key in map){
        let m = map[key];
        this.origins.push(m);
        if(m.parentId == 0 || m.parentId ==null){
          ms.push(m);
          if(!refresh){
            if( !this.currMenu)
              this.childrens.push(m);
          }else{
            this.childrens.push(m);
          }
        }
      }
      var root = {id:0, label:"根菜单", children: ms};
      this.menus.push(root);
      this.origins.push(root);
      
      if(!refresh && this.currMenu && this.currMenu.children.length>0){
        this.currMenu.children.forEach(m => this.childrens.push(m));
      } 

    },
    add(parent){
      this.parentMenu = parent;
      this.currMenu = {
        id:null, 
        label:null,
        icon:null,
        parentId: this.parentMenu.id,
        seq:0
      };
      this.dgTitle=this.msg("添加菜单");
      this.dgVisible = true;
    },
    editRow(row, type){
      if(type)
        this.parentMenu = this.origins.find(m => m.id == row.parentId);

      if(!this.parentMenu){
        this.parentMenu =  {id:0, label:"根菜单"};
      }
      // console.log("clicked edit", row);
      //console.log(this.menus);
      this.currMenu = row;
      delete this.currMenu["created"];
      this.dgTitle = this.msg("编辑菜单 - ")+this.currMenu.label;
      this.dgVisible = true;
    },
    deleteRow(row){
      //this.currMenu = row;
      //console.log("clicked delete", row);
      var _this = this;
      this.$confirm(this.fmt('确定要删除菜单《%s》吗?', [row.label]), this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {
        _this.$axios.post(_this.$kit.api.menuDelete, [row.id])
          .then(function(result){
            _this.$message(result.msg || _this.msg('删除成功'));
            _this.refresh(true);
        });
      });

    },
    selectNode(menu, node, self){
      this.parentMenu = menu;
      
      if(this.childrens.length>0)
        this.childrens.splice(0, this.childrens.length);

      if(menu.children){
        menu.children.forEach(m => this.childrens.push(m));
      }

    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.refresh(true);
      }
      this.dgVisible = false;
    },
  }
}
</script>

<style class="scss" scoped>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

</style>
